/* vietnamese */
@font-face {
  font-family: '__Space_Grotesk_8c6091';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/71075d1e01e0f56b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Grotesk_8c6091';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/eb615dea630ee3f8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Grotesk_8c6091';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bb45d86ce6501dc8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Grotesk_Fallback_8c6091';src: local("Arial");ascent-override: 88.82%;descent-override: 26.36%;line-gap-override: 0.00%;size-adjust: 110.78%
}.__className_8c6091 {font-family: '__Space_Grotesk_8c6091', '__Space_Grotesk_Fallback_8c6091', system-ui, arial;font-weight: 600;font-style: normal
}.__variable_8c6091 {--font-family-headline: '__Space_Grotesk_8c6091', '__Space_Grotesk_Fallback_8c6091', system-ui, arial
}

/*
@contract horizontal-scroll-arrow-button {
  color: Color
  position: Position
  margin: Margin
  hidden {
    display: Display
  }
  hover {
    color: Color
  }
  direction: Left
  box-shadow: BoxShadow
  dimensions: Width
  top: Top
  icon-dimensions: Width
}
*/

.horizontal-scroll-arrow-button_text__QSQb2 {
  color: var(--horizontal-scroll-arrow-button-color);
  width: var(--horizontal-scroll-arrow-button-icon-dimensions);
  height: var(--horizontal-scroll-arrow-button-icon-dimensions);
}

.horizontal-scroll-arrow-button_text__QSQb2.horizontal-scroll-arrow-button_disabled__I5QCR {
  color: var(--color-gray-300);
}

.horizontal-scroll-arrow-button_text__QSQb2:hover:not(.horizontal-scroll-arrow-button_disabled__I5QCR) {
  color: var(--horizontal-scroll-arrow-button-hover-color);
}

.horizontal-scroll-arrow-button_button__Lhd1G {
  pointer-events: auto;
  display: flex;
  top: var(--horizontal-scroll-arrow-button-top);
  margin: var(--horizontal-scroll-arrow-button-margin);
  background-color: var(--color-white);
  justify-content: center;
  align-items: center;
  width: var(--horizontal-scroll-arrow-button-dimensions);
  height: var(--horizontal-scroll-arrow-button-dimensions);
  border-radius: var(--border-radius-full);
  box-shadow: var(
    --horizontal-scroll-arrow-button-box-shadow,
    var(--shadow-100)
  );
  padding: 3px 7px;
}

.horizontal-scroll-arrow-button_right__Mcjd9 {
  right: var(--horizontal-scroll-arrow-button-direction, 0);
}

.horizontal-scroll-arrow-button_left__hReG9 {
  left: var(--horizontal-scroll-arrow-button-direction, 0);
}

.horizontal-scroll-arrow-button_disabled__I5QCR {
  visibility: var(--horizontal-scroll-arrow-button-disabled-visibility, hidden);
}

.horizontal-scroll-gradient_gradient__XKgAK {
  width: var(--horizontal-scroll-gradient-width, 50px);
  height: 100%;
  background-color: transparent;
}

.horizontal-scroll-gradient_left__PFXO8 {
  background: var(
    --horizontal-scroll-gradient-left-background,
    linear-gradient(
      to left,
      transparent 10%,
      var(--horizontal-scroll-gradient-background-color)
    )
  );
}

.horizontal-scroll-gradient_right__8b1dv {
  background: var(
    --horizontal-scroll-gradient-right-background,
    linear-gradient(
      to right,
      transparent 10%,
      var(--horizontal-scroll-gradient-background-color)
    )
  );
}

.horizontal-scroll-gradient_disabled__bVvz_ {
  visibility: hidden;
}

@layer components {
  .horizontal-scroll_container__1ho80 {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: var(--horizontal-scroll-container-position);
    padding: 0;
    margin: var(--horizontal-scroll-container-margin);
  }

  .horizontal-scroll_list__aPfb0 {
    position: var(--horizontal-scroll-list-position);
    width: 100%;
    list-style: none;
    display: var(--horizontal-scroll-list-display);
    scroll-snap-type: x proximity;
    margin: var(--horizontal-scroll-list-margin);
    padding: var(--horizontal-scroll-list-padding, 0);
    gap: var(--horizontal-scroll-list-gap);
    grid-auto-flow: column;
    overflow-x: var(--horizontal-scroll-list-overflow-x);
    overflow-y: hidden;
    justify-content: flex-start;
    align-items: var(--horizontal-scroll-list-align-items);
    scroll-behavior: var(--horizontal-scroll-list-scroll-behavior);
    height: var(--horizontal-scroll-list-height);
  }

  .horizontal-scroll_noScrollbar__40ae8 {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .horizontal-scroll_noScrollbar__40ae8::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }

  .horizontal-scroll_gradients__ymQOn {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    z-index: var(--z-index-1x);
    width: 100%;
  }

  .horizontal-scroll_arrows__lxuV3 {
    align-items: center;
    display: none;
    gap: var(--horizontal-scroll-arrows-gap, var(--space-2x));
    height: var(--horizontal-scroll-arrows-height, 100%);
    justify-content: var(
      --horizontal-scroll-arrows-justify-content,
      space-between
    );
    pointer-events: none;
    position: absolute;
    top: var(--horizontal-scroll-arrows-top, 0);
    width: var(--horizontal-scroll-arrows-width, 100%);
    z-index: var(--z-index-2x);
  }

  .horizontal-scroll_arrows__lxuV3:has(> button[disabled] + button[disabled]) {
    display: none;
  }
}
@media (any-pointer: fine) {

    .horizontal-scroll_arrows__lxuV3.horizontal-scroll_notMobile__Aftm7 {
        display: var(--horizontal-scroll-arrows-display, flex)
    }

    .horizontal-scroll_arrows__lxuV3:has(> button[disabled] + button[disabled]).horizontal-scroll_notMobile__Aftm7 {
        display: none
    }
}

.listing-card-carousel_slide__F7l3M {
  position: relative;
  object-fit: cover;
}

.listing-card-carousel_btnPrev__Hpf61,
.listing-card-carousel_btnNext__tqJno {
  width: 70px;
  height: 70px;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-top: -40px;
  touch-action: manipulation;
  position: absolute;
  top: 50%;
  z-index: var(--z-index-2x);
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

.listing-card-carousel_btnPrev__Hpf61::before,
.listing-card-carousel_btnNext__tqJno::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--color-gray-500);
  opacity: 0.6;
}

.listing-card-carousel_btnPrev__Hpf61 svg,
.listing-card-carousel_btnNext__tqJno svg {
  position: relative;
  z-index: var(--z-index-1x);
}

.listing-card-carousel_btnPrev__Hpf61 svg {
  left: var(--space-3x);
}

.listing-card-carousel_btnNext__tqJno svg {
  right: var(--space-3x);
}

.listing-card-carousel_btnPrev__Hpf61 {
  left: 0;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-left: -35px;
  padding-top: var(--space-2x);
}

.listing-card-carousel_btnNext__tqJno {
  right: 0;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-right: -35px;
  padding-top: var(--space-2x);
}

.listing-card-carousel_btnNext__tqJno:hover::before,
.listing-card-carousel_btnPrev__Hpf61:hover::before {
  background-color: var(--color-gray-600);
  opacity: 0.8;
}

.listing-card-carousel_carousel__AA822 {
  position: relative;
  overflow: hidden;
  background-color: var(--color-gray-200);
  height: 240px;
  min-width: 100%;
}

.listing-card-carousel_image__o1et9 {
  position: relative;
  object-fit: cover;
  display: var(--listing-card-carousel-display);
  height: var(--listing-card-carousel-height);
  width: var(--listing-card-carousel-width);
}

.listing-card-preview-carousel_carouselSection__7mPD8 {
  position: relative;
  z-index: var(--z-index-2x);
  width: auto;
  height: 177px;
  overflow: hidden;
}

.listing-card-preview-carousel_photoContainer__NLy_9 {
  overflow: hidden;
  border-bottom: none;
  border-radius: var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius) 0 0;
  min-width: 100%;
}

/*
@contract listing-card-preview {
  card-background-color {
    dark: BackgroundColor
    hover-dark: BackgroundColor
    light: BackgroundColor
    hover-light: BackgroundColor
  }
  info-card-color {
    dark: Color
    light: Color
    hover: {
      dark: Color
    }
  }
  info-card-details-text-color {
    dark: Color
    light: Color
  }
  border-radius: BorderRadius
  info-section-height: Height
  image-aspect-ratio: AspectRatio
}
*/

.listing-card-preview_link__MO7rB {
  text-decoration: none;
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.listing-card-preview_infoCardLight__jYY5_,
.listing-card-preview_infoCardDark__kt2tB {
  border-bottom-right-radius: var(--listing-card-preview-border-radius);
  border-bottom-left-radius: var(--listing-card-preview-border-radius);
  height: var(--listing-card-info-section-height);
}

.listing-card-preview_infoCardLight__jYY5_ {
  background: white;
  color: var(--listing-card-preview-info-card-color-light);
}

.listing-card-preview_infoCardDark__kt2tB {
  color: var(--listing-card-preview-info-card-color-dark);
}

.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_infoCardLight__jYY5_,
.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_infoCardDark__kt2tB {
  border: 0;
  border-bottom-right-radius: calc(
    var(--listing-card-preview-border-radius) / 2
  );
  border-bottom-left-radius: calc(
    var(--listing-card-preview-border-radius) / 2
  );
}

.listing-card-preview_cardLight__qGz17,
.listing-card-preview_cardDark__SwFGR {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--listing-card-wrapper-height);
  position: relative;
  border-radius: var(--listing-card-preview-border-radius);
  box-shadow: var(--listing-card-preview-info-card-shadow);
}

.listing-card-preview_cardLight__qGz17 {
  background-color: var(--listing-card-preview-card-background-color-light);
}

.listing-card-preview_cardLight__qGz17 .listing-card-preview_infoCardLight__jYY5_ {
  border-radius: 0 0 var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius);
  border: var(--listing-card-preview-border);
  border-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--listing-card-info-section-height);
}

.listing-card-preview_cardDark__SwFGR .listing-card-preview_infoCardDark__kt2tB {
  background-color: var(--listing-card-preview-card-background-color-dark);
}

.listing-card-preview_cardDark__SwFGR:hover .listing-card-preview_infoCardDark__kt2tB {
  background-color: var(
    --listing-card-preview-card-background-color-hover-dark
  );
}

.listing-card-preview_cardTextSmall__Mracz {
  padding: var(--space-3x);
  font-size: var(--font-size-body);
}

.listing-card-preview_cardTextMedium__XtXKe {
  padding: var(--space-3x);
  font-size: var(--font-size-body);
}

.listing-card-preview_infoCardFirstLine__k9XWD {
  display: flex;
  justify-content: space-between;
}

.listing-card-preview_ratingWrapper__HZPUy {
  --rating-star-size: var(--font-size-sm);
  --rating-size: var(--font-size-sm);
}

.listing-card-preview_name__mUL4P,
.listing-card-preview_price___uEPc {
  font-weight: bold;
}

.listing-card-preview_name__mUL4P,
.listing-card-preview_price___uEPc,
.listing-card-preview_detailTextDark__k_cuW,
.listing-card-preview_detailTextLight__YRz7n {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-card-preview_detailTextLight__YRz7n {
  color: var(--listing-card-preview-info-card-details-text-color-light);
}

.listing-card-preview_detailTextDark__k_cuW {
  color: var(--listing-card-preview-info-card-details-text-color-dark);
}

.listing-card-preview_cardDark__SwFGR:hover .listing-card-preview_detailTextDark__k_cuW,
.listing-card-preview_cardDark__SwFGR:hover .listing-card-preview_infoCardDark__kt2tB {
  color: var(--listing-card-preview-info-card-color-hover-dark);
}

.listing-card-preview_photoWrapper__DVAHE picture {
    display: contents;
  }

.listing-card-preview_photo__1M4E2 {
  border: var(--listing-card-preview-border);
  border-bottom: none;
  border-radius: var(--listing-card-preview-border-radius)
    var(--listing-card-preview-border-radius) 0 0;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: var(--listing-card-preview-image-aspect-ratio);
}

.listing-card-preview_todaysPick__MFT_5 {
  overflow: visible;
  overflow: initial;
  position: relative;
  box-shadow: var(--listing-card-preview-info-card-shadow);
  border-top-left-radius: 0;
}

.listing-card-preview_todaysPick__MFT_5::before,
  .listing-card-preview_todaysPick__MFT_5::after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% - 4px);
    box-shadow: 0 0 0 4px var(--listing-card-preview-primary-color);
    pointer-events: none;
  }

.listing-card-preview_todaysPick__MFT_5::after {
    border-radius: 0 8px 8px;
  }

.listing-card-preview_todaysPick__MFT_5::before {
    border-radius: 8px;
  }

.listing-card-preview_todaysPickText__pdh9j {
  gap: var(--space-1x);
  background: var(--listing-card-preview-primary-color);
  color: white;
  position: absolute;
  height: 28px;
  top: 0;
  left: 0;
  transform: translate(
    calc(calc(var(--listing-card-preview-border-radius) / 2) * -1),
    -100%
  );
  font-size: 12px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  padding: var(--space-2x);
  border-radius: calc(var(--listing-card-preview-border-radius) / 2)
    calc(var(--listing-card-preview-border-radius) / 2) 0 0;
}

.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_cardTextSmall__Mracz {
  padding: var(--space-3x) calc(var(--space-3x) - 4px) var(--space-3x);
}

.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_cardTextMedium__XtXKe {
  padding: var(--space-3x) calc(var(--space-3x) - 4px) var(--space-3x);
}

.listing-card-preview_ctaButtons__8nBrG {
  padding-top: var(--space-3x);
  --icon-button-width: 100%;
  --button-font-size: var(--font-size-body);
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listing-card-preview_contactedOn__Sod_q {
  background: var(--color-gray-100);
  padding: var(--space-0-5x) var(--space-1x);
  border-radius: var(--space-1x);
  position: absolute;
  color: var(--color-gray-600);
  font-weight: var(--font-weight-semibold);
  top: var(--space-2x);
  font-size: var(--font-size-sm);
  left: var(--space-3x);
}

@media (min-width: 768px) {

.listing-card-preview_cardTextMedium__XtXKe {
    font-size: var(--font-size-md);
    padding: var(--space-4x)
}

.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_cardTextMedium__XtXKe {
    padding: var(--space-4x) calc(var(--space-4x) - 4px) var(--space-4x)
}
}

@media (min-width: 1126px) {

.listing-card-preview_cardTextMedium__XtXKe {
    padding: 18px
}

.listing-card-preview_todaysPick__MFT_5 .listing-card-preview_cardTextMedium__XtXKe {
    padding: 18px 14px 14px
}
}

@media (min-width: 1200px) {

.listing-card-preview_cardTextMedium__XtXKe {
    font-size: 18px
}
}

/*
@contract listing-card-skeleton {
  height: Height
  carousel-height: Height
  content-gap: Gap
  content-padding: Padding
}
*/

.listing-card-skeleton_listingCardSkeleton__d_JOB {
  padding-bottom: 0;
  height: var(--listing-card-skeleton-height);
}

.listing-card-skeleton_listingCardSkeletonInner__H_8if {
  display: flex;
  flex-direction: column;
  animation: listing-card-skeleton_pulse__oZ1L_ 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes listing-card-skeleton_pulse__oZ1L_ {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.listing-card-skeleton_listingCardSkeletonCarousel__jgr1p {
  height: var(--listing-card-skeleton-carousel-height);
  background: var(--color-gray-200);
}

.listing-card-skeleton_listingCardSkeletonContent__r2rt9 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--listing-card-skeleton-content-padding);
  gap: var(--listing-card-skeleton-content-gap);
}

.listing-card-skeleton_listingCardSkeletonBlocks__ph09t {
  height: 100%;
  flex: 1 1;
  grid-gap: var(--space-4x);
  gap: var(--space-4x);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.listing-card-skeleton_listingCardSkeletonBlock__04mx1 {
  height: 19px;
  background: var(--color-gray-200);
  border-radius: var(--border-radius-2x);
}

.listing-card-skeleton_listingCardSkeletonCta__v_dHf {
  height: 35px;
}

.ad-slot_slot__alsPH {
  display: flex;
  place-content: center;
  overflow: hidden;
}

.ad-slot_hidden__4h8q_ {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important; /* overriding vendor styles */
}

/*
@contract listing-card-preview-group {
  columns: GridTemplateColumns
  gap: Gap
  li {
    width: Width
    height: Height
  }
}
*/
@layer molecules {
  .listing-card-preview-group_listingCardPreviewGroupSkeleton__gNn0X {
    width: 100%;
  }

  .listing-card-preview-group_container___cTr5 {
    --horizontal-scroll-arrows-top: -50px;
    --horizontal-scroll-list-gap: var(--space-5x);
    --horizontal-scroll-list-display: grid;
    --horizontal-scroll-gradient-width: 15px;
    --horizontal-scroll-arrows-height: auto;
  }

  .listing-card-preview-group_listingCardPreviewGroupUlVertical__d_If8 {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    position: relative;
    font-size: var(--font-size-body);
    justify-content: flex-start;
    gap: var(--space-5x);
    list-style: none;
    grid-template-columns: var(--listing-card-preview-group-columns);
  }

  .listing-card-preview-group_listingCardPreviewGroupLiHorizontal__00DgG {
    width: var(--listing-card-preview-group-li-width);
    height: var(--listing-card-preview-group-li-height);
  }

  .listing-card-preview-group_listingCardPreviewGroupLiVertical__GcLMO {
    width: 100%;
  }

  .listing-card-preview-group_listingCardPreviewGroupFirstLi__ujh_p {
    order: 1;
  }

  .listing-card-preview-group_listingCardPreviewGroupBelowAdLi__KXDXR {
    order: 3;
  }

  .listing-card-preview-group_listingCardPreviewGroupBelowAdLi__KXDXR:nth-last-child(2) {
    margin-bottom: 3rem;
  }

  .listing-card-preview-group_listingCardPreviewGroupAd__1IqdO {
    order: 2;
    min-height: auto;
  }
}
@media (min-width: 600px) {

  .listing-card-preview-group_listingCardPreviewGroupUlVertical__d_If8 {
      display: grid;
      grid-auto-columns: 1fr 1fr
  }

  .listing-card-preview-group_listingCardPreviewGroupAd__1IqdO {
      order: 999
  }
}
@media (min-width: 768px) {

  .listing-card-preview-group_listingCardPreviewGroupLiVertical__GcLMO {
      max-height: var(--list-item-max-height)
  }
      .listing-card-preview-group_listingCardPreviewGroupLiVertical__GcLMO:hover {
        box-shadow: 0 4px 8px -2px rgb(0 0 0 / 10%);
      }

  .listing-card-preview-group_listingCardPreviewGroupBelowAdLi__KXDXR:nth-last-child(2) {
      margin-bottom: 0
  }
}
@media (min-width: 1024px) {

  .listing-card-preview-group_listingCardPreviewGroupUlVertical__d_If8 {
      grid-auto-columns: 1fr 1fr 1fr
  }
}
@media (any-pointer: fine) {

  .listing-card-preview-group_container___cTr5 {
      --horizontal-scroll-arrow-button-disabled-visibility: visible;
      --horizontal-scroll-arrows-justify-content: flex-end;
      --horizontal-scroll-arrows-top: -40px
  }
}

.listing-card-preview-group_listingCardPreviewTheme__2sIZN {
  --list-item-max-height: 410px;
}

